import { Component, Input } from '@angular/core';

import { NavigationNode, VersionInfo } from 'app/navigation/navigation.service';

@Component({
  selector: 'aio-footer',
  template: `<p>
          RxJS v7.8 中文网 - 粤ICP备13048890号
          <br/><a href="https://nodejs.cn/#rx" target="_blank">Nodejs.cn 旗下网站</a>
          </p>`,
        })
export class FooterComponent {
  @Input() nodes: NavigationNode[];
  @Input() versionInfo: VersionInfo;
}
